<template>
<div v-if="reloadForm == false">
    <div v-if="item">
        <div class="grid">
                <div class="col-12">
                    <div class="card">
                        <Toast/>
                        <Error :errors="errors"/>

                        <Button label="Beat User" class="p-button-rounded mb-3" />
                        <Message severity="info" :closable="false">
                            <div>Beat Code : {{this.item.beat_code}}</div>
                            <div>Beat Name : {{this.item.beat_name ? this.item.beat_name : '-' }}</div>
                        </Message>

                        <Fieldset legend="Add New" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                            <Create :id="beat_id" :item="item" @submit="getDataTable()" ref="create"/>
                        </Fieldset>

                        <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('read')">
                            <Filter @submit="getDataTable()" ref="filter"/>
                        </Fieldset>

                        <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit" v-if="permission.includes('read')">
                            <template #header>
                                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                    <div>
                                        <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" v-if="permission.includes('download')"/>
                                        <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" v-if="permission.includes('download')"/>
                                    </div>
                                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                                        <i class="pi pi-search" />
                                        <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                                    </span>
                                </div>
                            </template>
                            <Column field="number" header="No." :style="{width:'20px'}" >
                                <template #body="slotProps">
                                {{ slotProps.data.number }}
                                </template>
                            </Column>
                            <Column field="beat_code" header="Beat Code">
                                <template #body="slotProps">
                                    {{slotProps.data.beat_code}}
                                </template>
                            </Column>
                            <Column field="beat_name" header="Beat">
                                <template #body="slotProps">
                                    {{slotProps.data.beat_name}}
                                </template>
                            </Column>
                            <Column field="db_code" header="Distributor Code">
                                <template #body="slotProps">
                                    {{slotProps.data.db_code}}
                                </template>
                            </Column>
                            <Column field="db_name" header="Distributor Name">
                                <template #body="slotProps">
                                    {{slotProps.data.db_name}}
                                </template>
                            </Column>
                            <Column field="territory_name" header="Territory">
                                <template #body="slotProps">
                                    {{slotProps.data.territory_name}}
                                </template>
                            </Column>
                            <Column field="city_name" header="City">
                                <template #body="slotProps">
                                    {{slotProps.data.city_name}}
                                </template>
                            </Column>


                            <Column field="mcu_code" header="Username">
                                <template #body="slotProps">
                                    {{slotProps.data.mcu_code}}
                                </template>
                            </Column>
                            <Column field="mcu_name" header="Name">
                                <template #body="slotProps">
                                    {{slotProps.data.mcu_name}}
                                </template>
                            </Column>
                            <Column field="beat_status" header="Beat User Status">
                                <template #body="slotProps">
                                    <div v-if="permission.includes('update')">
                                        <Status 
                                            :item="slotProps.data" 
                                            :status="slotProps.data.beat_status" 
                                            :url="'web/beat-user/update'"
                                            :data="{ 
                                                beat_id: beat_id, 
                                                mcu_id: slotProps.data.mcu_id 
                                            }"
                                            @submit="getDataTable()"
                                        />
                                    </div>
                                    <div v-else>
                                        <Button icon="pi pi-check" class="p-button-rounded" v-if="slotProps.data.beat_status == 'y'"/>
                                        <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-else/>
                                    </div>
                                </template>
                            </Column>
                            <template #empty>
                                No data found.
                            </template>
                            <template #loading>
                                Loading data. Please wait.
                            </template>
                            <template #footer>
                                In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                            </template>
                        </DataTable>

                        <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]" v-if="permission.includes('read')">
                            <template>
                                {{totalItemsCount}}
                            </template>
                        </Paginator>
                    </div>
            </div>
        </div>
    </div>
    <div v-else>
        <h3 class="text-center">Beat Not Found</h3>
    </div>
</div>
<div v-else>
    <div class="card">
        <div class="grid formgrid">
            <div class="field col-12 md:col-12">
                <Skeleton class="mb-2"></Skeleton>
                <Skeleton width="10rem" class="mb-2"></Skeleton>
                <Skeleton width="5rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton width="100%" height="10rem"></Skeleton>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../../../utils/helpers';
import Filter from './Filter.vue';
import Create from './Create.vue';
import Status from './Status.vue';
import Error from '../../../Error.vue';

export default {
    components: {
		'Filter': Filter,
		'Create': Create,
		'Status': Status,
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,
            reloadForm: false,

            beat_id: null,

            // edit,delete
            item: {},

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    created() {
        this.beat_id = this.$route.params.id;
        this.getItemById(this.beat_id);
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),

		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/beat-user',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "beat_id" : this.beat_id,
                    "territory_id" : this.$refs.filter.filters.territory_id,
                    "city_id" : this.$refs.filter.filters.city_id,
                }
            })
            .then(res => {
                this.data = res.data.data.data;
                this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                this.totalItemsCount = res.data.data.total;
                this.rows = parseInt(res.data.data.per_page);
                this.loading=false;
                this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/beat-user/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "beat_id" : this.beat_id,
                "territory_id" : this.$refs.filter.filters.territory_id,
                "city_id" : this.$refs.filter.filters.city_id,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Beat User Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
        getItemById(id) {
            this.reloadForm = true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/beat-user/check',
                params: {
                    "beat_id": id,
                }
            })
            .then(res => {
                this.reloadForm = false;

                if (res) {

                    this.$store.commit('setErrors', {});
                    this.item = res.data;

                    setTimeout(() => {
                        this.getDataTable();
                    }, 200);

                }
            }, (this))
            .catch((err) => {
                this.item = null;
                console.log(err);
                this.reloadForm = false;
            });
        }
    }
}
</script>